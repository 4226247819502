<template>
<main id="main" class="comp">
  <div class="wrap1160">
    <p class="comp-msg" v-html="$t('MEMBER_REQUEST_CONFIRM_EXPLANATION_MESSAGE')"></p>
    <section id="entry-form" class="bgLGray">
      <form>
        <table class="tbl-entry">
          <tbody>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_NICKNAME_LABEL')"></th>
              <td>
                {{registerData.nickname}}
                <p v-if="errors && errors.nickname" class="err-txt">{{errors.nickname}}</p>
                <p v-if="errors && errors.nickname_dup" class="err-txt">{{errors.nickname_dup}}</p>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_NAME_LABEL')"></th>
              <td>
                {{registerData.name}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_FURIGANA_LABEL')"></th>
              <td>
                {{registerData.furigana}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_BIRTHDAY_LABEL')"></th>
              <td>
               {{ registerData.birthday | dateFormat }}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_POST_CODE_LABEL')"></th>
              <td>
                {{registerData.postCode}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_FORM_ADDRESS_LABEL')"></th>
              <td>
                {{registerData.address}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_SHIP_POST_CODE_LABEL')"></th>
              <td>
                {{registerData.shipPostCode}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_SHIP_ADDRESS_LABEL')"></th>
              <td>
                {{registerData.shipAddress}}
              </td>
            </tr>
            <tr>
              <th>
                {{$t('MEMBER_REQUEST_FORM_TEL_LABEL')}}<br />
              </th>
              <td>
                {{registerData.tel || ''}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_EMAIL_LABEL')"></th>
              <td>
                {{registerData.email}}
                <p v-if="errors && errors.email" class="err-txt">{{errors.email}}</p>
                <p v-if="errors && errors.email_dup" class="err-txt">{{errors.email_dup}}</p>
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_CONFIRM_EMAIL_LABEL')"></th>
              <td>
                {{registerStore.confirmEmail}}
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_PASSWORD_LABEL')"></th>
              <td>
                ********
              </td>
            </tr>
            <tr>
              <th v-html="$t('MEMBER_REQUEST_CONFIRM_CONFIRM_PASSWORD_LABEL')"></th>
              <td>
                ********
              </td>
            </tr>
            <tr>
              <th>
                {{$t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL') + $t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL2')}}
              </th>
              <td v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></td>
            </tr>
          </tbody>
        </table>
        <div class="btn-form">
          <input type="button" class="btn-back" :value="$t('MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL')" @click="registerStore.ruleCheckBtnClicked=false;registerStore.editFlag=true">
          <input type="button" :value="$t('MEMBER_REQUEST_CONFIRM_SEND_BUTTON_LABEL')" @click="registerMemberRequest">
        </div>
      </form>
    </section>
  </div>
</main>
</template>

<script>
import Methods from '@/api/methods'
import CommonFilters from '@/common/commonFilters'
import useLoading from '@/stores/useLoading'
import useRegister from '@/stores/useRegister'
export default {
  setup() {
    const registerStore = useRegister()
    const loadingStore = useLoading()
    return {
      loadingStore,
      registerStore
    }
  },
  components : {
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  mixins : [CommonFilters],
  data() {
    return {
      errors : {}
    }
  },
  computed : {
    registerData() {
      return this.registerStore.registerData
    }
  },
  methods : {
    registerMemberRequest() {
      const params = {
        registerData : this.registerStore.registerData
      }
      this.loadingStore.setLoading(true)
      Methods.apiExcute('request-member', this.$i18n.locale, params).then(data => {
        this.loadingStore.setLoading(false)
        this.registerStore.completedFlag = true
        window.scrollTo({
          top      : 0,
          left     : 0,
          behavior : 'smooth'
        })
      })
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.errors = Object.assign(Methods.parseHtmlResponseError(this.$router, error), this.errors)
        })
    }
  }
}
</script>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
