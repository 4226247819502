<template>
<div>
  <Register v-if="editFlag" />
  <Completion v-else-if="completedFlag" />
  <Comfirm v-else />
</div>
</template>

<script>
import useLoading from '@/stores/useLoading'
import useRegister from '@/stores/useRegister'
import {defineComponent} from '@vue/composition-api'
import Comfirm from '../components/RegisterMember/Comfirm'
import Completion from '../components/RegisterMember/Completion'
import Register from '../components/RegisterMember/Register'
export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const registerStore = useRegister()
    return {
      loadingStore,
      registerStore
    }
  },
  components : {
    Comfirm,
    Register,
    Completion
  },
  data() {
    return {
    }
  },
  methods : {
  },
  computed : {
    editFlag() {
      return this.registerStore.editFlag
    },
    completedFlag() {
      return this.registerStore.completedFlag
    },
  },
  created() {
    this.registerStore.reset()
    this.registerStore.getMemberRegisterConstants()
  },
  watch : {
    '$i18n.locale'() {
      this.registerStore.getMemberRegisterConstants()
    }
  }
})
</script>
