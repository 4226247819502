import i18n from '@/language/i18n'
import router from '@/router'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useLoading from './useLoading'
const loadingStore = useLoading()

const useRegister = defineStore('useRegister', {
  state : () => {
    return {
      locale          : 'ja',
      mode            : 'RegisterMember',
      memberRequestNo : null,
      registerData    : {
        address         : '',
        shipAddress     : null,
        password        : null
      },
      constants       : [],
      editFlag        : true,
      completedFlag   : false,
      confirmEmail    : null,
      confirmPassword : null,
      errors          : {},

      // Rule check button clicked
      ruleCheckBtnClicked : false,
    }
  },
  getters : {
  },
  actions : {
    reset() {
      this.memberId = null
      this.memberRequestNo = null
      this.registerData = {
        address         : '',
        shipAddress     : null,
        password        : null
      }
      this.constants = []
      this.editFlag = true
      this.completedFlag = false
      this.confirmEmail = null
      this.confirmPassword = null
      this.errors = {}
      // Rule check button clicked
      this.ruleCheckBtnClicked = false
    },
    request(params) {
      return Methods.apiExcute('request-member', this.locale, params)
    },
    extraValidate() {
      if (!this.confirmEmail) {
        this.errors.confirmEmail = i18n.t('MEMBER_INFO_EMPTY_MESSAGE')
      } else if (this.registerData.email && this.registerData.email !== this.confirmEmail) {
        this.errors.confirmEmail = i18n.t('MAIL_CONFIRM_ERROR_MESSAGE')
      }
      if (!this.confirmPassword) {
        this.errors.confirmPassword = i18n.t('MEMBER_INFO_EMPTY_MESSAGE')
      } else if (this.registerData.password && this.registerData.password !== this.confirmPassword) {
        this.errors.confirmPassword = i18n.t('PASSWORD_CONFIRM_ERROR_MESSAGE')
      }
    },
    getMemberRegisterConstants() {
      loadingStore.setLoading(true)
      Methods.apiExcute('get-member-regist-constants', this.locale).then(data => {
        loadingStore.setLoading(false)
        this.constants = data
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    searchPostCode() {
      if (this.registerData.postCode) {
        this.registerData.postCode = this.registerData.postCode.trim()
      }
      const params = {
        postCode : this.registerData.postCode
      }
      loadingStore.setLoading(true)
      Methods.apiExcute('search-post-code', this.locale, params).then(data => {
        loadingStore.setLoading(false)
        this.registerData.address = (data.address1 || '') + (data.address2 || '') + (data.address3 || '')
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    searchShipPostCode() {
      console.log('searchShipPostCode')
      if (this.registerData.shipPostCode) {
        this.registerData.shipPostCode = this.registerData.shipPostCode.trim()
      }
      const params = {
        postCode : this.registerData.shipPostCode
      }
      loadingStore.setLoading(true)
      Methods.apiExcute('search-post-code', this.locale, params).then(data => {
        loadingStore.setLoading(false)
        this.registerData.shipAddress = (data.address1 || '') + (data.address2 || '') + (data.address3 || '')
      })
        .catch(error => {
          loadingStore.setLoading(false)
          const errs = Methods.parseHtmlResponseError(router, error)
          this.errors = {shipPostCode : errs.postCode}
        })
    },
    validate($refs) {
      console.log('validate')
      if (this.ruleCheckBtnClicked === false) {
        return
      }

      // Trim
      Object.keys(this.registerData).forEach(x => {
        if (x !== 'password' && x !== 'email' && this.registerData[x]) {
          this.registerData[x] = this.registerData[x].trim()
        }
      })

      const params = {
        memberRequestNo : this.memberRequestNo || null,
        registerData    : this.registerData,
        validateFlag    : true
      }
      this.errors = {}
      this.checkInputFormat()
      loadingStore.setLoading(true)

      console.log('params = ', params)
      this.request(params)
        .then(() => {
          if (Object.keys(this.errors).length === 0) {
            loadingStore.setLoading(false)
            this.editFlag = false
            window.scrollTo({
              top      : 0,
              left     : 0,
              behavior : 'smooth'
            })
          } else {
            loadingStore.setLoading(false)
          }
        })
        .catch(error => {
          console.log('error', error)
          loadingStore.setLoading(false)
          // 表示順のため
          let default_errs = {
            nickname         : null,
            nickname_dup     : null,
            name             : null,
            furigana         : null,
            birthday         : null,
            postCode         : null,
            address          : null,
            shipPostCode     : null,
            shipAddress      : null,
            tel              : null,
            email            : null,
            email_dup        : null,
            confirmEmail     : null,
            password         : null,
            confirmPassword  : null
          }
          default_errs = Object.assign(default_errs, this.errors, Methods.parseHtmlResponseError(router, error))
          const tmp_errs = {}
          Object.keys(default_errs).map(x => {
            if (default_errs[x]) {
              tmp_errs[x] = default_errs[x]
            }
          })
          this.errors = Object.assign({}, tmp_errs)

          // Move pointer to first error item
          this.moveToError($refs)
        })
    },
    checkInputFormat() {
      console.log('checkInputFormat')
      let tags = [
        'nickname',
        'name',
        'furigana',
      ]

      if (this.mode === 'RegisterMember') {
        tags = [
          'nickname',
          'name',
          'furigana',
          'address',
          'email'
        ]
      }

      const pattern = new RegExp('^[0-9a-zA-Z -/:-@\[-~]+$')
      const jpPattern = new RegExp('^([^\x01-\x7E]|[0-9a-zA-Z -/:-@\[-~])+$')
      this.errors = {}
      for (const tag of tags) {
        if (this.registerData[tag]) {
          if (!jpPattern.test(this.registerData[tag])) {
            this.errors[tag] = i18n.t('MEMBER_REQUEST_TEXT_FORMAT_ERROR')
          }
        }
      }
      this.extraValidate()
    },
    isJapanView() {
      return true
    },
    ruleCheckClick() {
      // 更新ボタンの活性・非活性
      if (document.getElementById('rule-chk').checked === false) {
        console.log('uncheck')
        this.ruleCheckBtnClicked = false
      } else {
        console.log('checked')
        this.ruleCheckBtnClicked = true
      }
    },
    moveToError($refs) {
      const keys = Object.keys(this.errors)
      if (keys.length > 0) {
        let refkey = keys[0]
        if (keys[0] === 'email_dup') {
          refkey = 'email'
        } else if (keys[0] === 'nickname_dup') {
          refkey = 'nickname'
        }
        if (this.errors[keys[0]] && $refs[refkey]) {
          if (refkey === 'birthday') $refs[refkey].scrollIntoView()
          else $refs[refkey].focus()
        }
      }
    }
  },
})

export default useRegister
